<template>
  <div class="columns is-centered mt-5">
    <div class="column is-3 box mt-5 p-5">
      <form class>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <b-field>
            <ValidationProvider
              rules="required|userpass"
              v-slot="{ errors, valid }"
              vid="confirmation"
            >
              <b-field
                :label="t('auth.newPassword')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="t(errors[0])"
                label-position="on-border"
              >
                <b-input
                  :disabled="requestInProgress"
                  v-model="password"
                  type="password"
                  icon="eye-off-outline"
                  custom-class="has-text-centered"
                  expanded
                ></b-input>
              </b-field>
            </ValidationProvider>
          </b-field>
          <b-field>
            <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors, valid }">
              <b-field
                :label="t('auth.repeatPassword')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="t(errors[0])"
                label-position="on-border"
              >
                <b-input
                  :disabled="requestInProgress"
                  v-model="repeat"
                  type="password"
                  icon="eye-off-outline"
                  custom-class="has-text-centered"
                  expanded
                ></b-input>
              </b-field>
            </ValidationProvider>
          </b-field>

          <p class="has-text-centered">
            <b-button
              :loading="requestInProgress"
              :disabled="invalid"
              @click="attemptPasswordChange"
              type="is-primary"
            >{{t('common.save')}}</b-button>
          </p>
        </ValidationObserver>
        <p
          v-for="item in errors"
          :key="item.index"
          class="has-text-danger has-text-centered"
        >{{ item }}</p>
        <p class="has-text-centered mt-3">
          <b-button @click="$router.push({name: 'login'})" size="is-small" type="is-text">{{t('common.return')}}</b-button>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { Action } from '@/store/config_actions'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import CommonMixins from '@/mixins/commons'

export default {
  name: 'IdentityPasswordReset',
  mixins: [CommonMixins],

  props: {
    token: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },

  components: {
    ValidationProvider, ValidationObserver
  },

  data: function () {
    return {
      /** New user email */
      password: null,

      /** Password for new user */
      repeat: null,

      /** Server response errors  */
      errors: [],

      requestInProgress: false
    }
  },

  methods: {

    // Attempt to register user.
    attemptPasswordChange() {
      this.errors = []
      this.requestInProgress = true
      var credentials = { password: this.password, token: this.token, email: this.email }

      // Dispatch API call.
      this.$store.dispatch(Action.AUTH_RESET_PASSWORD, credentials)
        .then(() => {
          this.mSuccess(this.t('auth.passwordChanged'))
          this.$router.push({ name: 'login' })
        })
        .catch((error) => {
          this.requestInProgress = false
          this.mDanger(error)
        });
    },
  }
}
</script>

<style scoped lang="scss">
</style>
