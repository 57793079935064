var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-centered mt-5"},[_c('div',{staticClass:"column is-3 box mt-5 p-5"},[_c('form',{},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"required|userpass","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.t('auth.newPassword'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0]),"label-position":"on-border"}},[_c('b-input',{attrs:{"disabled":_vm.requestInProgress,"type":"password","icon":"eye-off-outline","custom-class":"has-text-centered","expanded":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.t('auth.repeatPassword'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0]),"label-position":"on-border"}},[_c('b-input',{attrs:{"disabled":_vm.requestInProgress,"type":"password","icon":"eye-off-outline","custom-class":"has-text-centered","expanded":""},model:{value:(_vm.repeat),callback:function ($$v) {_vm.repeat=$$v},expression:"repeat"}})],1)]}}],null,true)})],1),_c('p',{staticClass:"has-text-centered"},[_c('b-button',{attrs:{"loading":_vm.requestInProgress,"disabled":invalid,"type":"is-primary"},on:{"click":_vm.attemptPasswordChange}},[_vm._v(_vm._s(_vm.t('common.save')))])],1)]}}])}),_vm._l((_vm.errors),function(item){return _c('p',{key:item.index,staticClass:"has-text-danger has-text-centered"},[_vm._v(_vm._s(item))])}),_c('p',{staticClass:"has-text-centered mt-3"},[_c('b-button',{attrs:{"size":"is-small","type":"is-text"},on:{"click":function($event){return _vm.$router.push({name: 'login'})}}},[_vm._v(_vm._s(_vm.t('common.return')))])],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }